<div *ngIf="messages.length; else noMessages" class="flex flex-column h-full w-auto">
  <div
    class="overflow-y-auto p-3 md:px-4 lg:px-6 lg:py-4 mt-2"
    style="overflow-x: clip"
    #chatWindow
  >
    <div *ngFor="let message of messages">
      <div *ngIf="message?.role !== EAIChatRole.USER" class="mb-4">
        <div class="mt-1 flex align-items-center">
          <img
            src="assets/images/logo.png"
            alt="user.name"
            class="w-3rem h-3rem border-circle shadow-4 mr-3"
          >
          <p class="text-900 font-semibold">{{message.username}}</p>
        </div>
        <div class="col mt-3">
          <span
            class="text-700 inline-block font-medium border-1 surface-border p-3 white-space-normal border-round w-full"
            style="max-width: 700px"
          >
            <markdown
              [clipboard]="true"
              [data]="message.content"
            ></markdown>
          </span>
          <p class="text-700 mt-3">
            {{message.createdAt}}
            <i class="pi pi-check text-green-400 ml-2"></i>
          </p>
        </div>
      </div>

      <div *ngIf="message?.role === EAIChatRole.USER" class="grid grid-nogutter mb-4">
        <div class="col mt-3 text-right">
          <span class="inline-block text-left font-medium border-1 surface-border bg-primary-100 text-primary-900 p-3 white-space-normal border-round"
          >
            {{message.content}}
          </span>
          <p class="text-700 mt-3">
            {{message.createdAt}}
            <i class="pi pi-check text-green-400 mr-3" ></i>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noMessages>
   <div class="text-lg flex flex-column h-full w-full align-items-center justify-content-center">
     {{'msg.ai-chat.chat-no-messages' | translate}}
   </div>
</ng-template>
