import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { KdConfigService, cleanObjectUndefined } from '@selfai-platform/shared';
import { Observable, map, mergeMap, of } from 'rxjs';
import { CUSTOM_KD_API_URL } from '../tokens';

type QueryParams = Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>>;

@Injectable()
export class KdBackendApiService {
  get rootApiUrl$(): Observable<string> {
    const apiUrl$ = this.customKdApiUrl$ || of(this.kdConfigService.getConfig().apiUrl);

  return apiUrl$.pipe(
      map((apiUrl) => {
        return this.concatUrlWithPath(apiUrl, 'api');
      }),
    );
  }

  constructor(
    private readonly http: HttpClient,
    private readonly kdConfigService: KdConfigService,
    @Optional() @Inject(CUSTOM_KD_API_URL) private readonly customKdApiUrl$?: Observable<string>,
  ) {}

  get<T>(path: string): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.getWithHeaders<T>(path, headers);
  }

  getFormData<T>(path: string): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });

    return this.getWithHeaders<T>(path, headers);
  }

  getHTML<T>(path: string): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'text/html',
    });

    return this.getWithHeaders<T>(path, headers);
  }

  getBlob(path: string): Observable<Blob> {
    const headers = new HttpHeaders({
      Accept: '*/*',
    });

    return this.concatApiUrl(path).pipe(mergeMap((url) => this.http.get(url, { headers, responseType: 'blob' })));
  }

  post<T>(path: string, data: unknown): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = JSON.stringify(data);

    return this.postWithHeaders<T>(path, body, headers);
  }

  postWithForm<T>(path: string, data: unknown): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.postWithHeaders<T>(path, data, headers);
  }

  postBinary<T>(path: string, data: unknown): Observable<T> {
    const headers = new HttpHeaders({
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json',
    });
    const body = JSON.stringify(data);

    return this.postWithHeaders<T>(path, body, headers).pipe(
      map((response) => {
        return (response as { _body: T })._body;
      }),
    );
  }

  patch<T>(url: string, data: unknown): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const body = JSON.stringify(data);

    return this.patchWithHeaders<T>(url, body, headers);
  }

  patchUriList<T>(url: string, data: unknown): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'text/uri-list',
    });

    return this.patchWithHeaders<T>(url, data, headers);
  }

  put<T>(path: string, data: unknown): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const params = JSON.stringify(data);

    return this.putWithHeaders<T>(path, params, headers);
  }

  putTextUrlList<T>(path: string, data: unknown): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'text/uri-list',
    });

    return this.putWithHeaders<T>(path, data, headers);
  }

  delete<T>(path: string): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.concatApiUrl(path).pipe(mergeMap((url) => this.http.delete<T>(url, { headers })));
  }

  buildPath<T = QueryParams | undefined>(path: string, queryParams?: T): string {
    if (!queryParams) {
      return path;
    }

    return path + '?' + this.buildQueryParams(queryParams as QueryParams);
  }

  private buildQueryParams(queryParams: QueryParams): string {
    return new HttpParams({
      fromObject: cleanObjectUndefined(queryParams),
    }).toString();
  }

  private putWithHeaders<T>(path: string, data: unknown, headers: HttpHeaders): Observable<T> {
    return this.concatApiUrl(path).pipe(mergeMap((url) => this.http.put<T>(url, data, { headers })));
  }

  private patchWithHeaders<T>(path: string, data: unknown, headers: HttpHeaders): Observable<T> {
    return this.concatApiUrl(path).pipe(mergeMap((url) => this.http.patch<T>(url, data, { headers })));
  }

  protected postWithHeaders<T>(path: string, data: unknown, headers: HttpHeaders): Observable<T> {
    return this.concatApiUrl(path).pipe(mergeMap((url) => this.http.post<T>(url, data, { headers })));
  }

  private getWithHeaders<T>(path: string, headers: HttpHeaders): Observable<T> {
    return this.concatApiUrl(path).pipe(mergeMap((url) => this.http.get<T>(url, { headers })));
  }

  private concatApiUrl(path: string): Observable<string> {
    return this.rootApiUrl$.pipe(
      map((apiUrl) => {
        return this.concatUrlWithPath(apiUrl, path);
      }),
    );
  }

  private concatUrlWithPath(url: string, path: string): string {
    let normalizedPath = path;
    let normalizedUrl = url;

    if (normalizedUrl.endsWith('/')) {
      normalizedUrl = url.slice(0, -1);
    }

    if (normalizedPath.startsWith('/')) {
      normalizedPath = path.substring(1);
    }

    return `${normalizedUrl}/${normalizedPath}`;
  }
}
