<selfai-platform-dialog-header
  [header]="'workflow.cubes.enrichment-tool.modal-header' | translate"
  [nodeId]="nodeId"
  (clickSave)="onSubmit()"
  (closeDialog)="onCloseDialog()"
></selfai-platform-dialog-header>

@if(hasParameters) {
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool-batch.field-label.jdbc-driver' | translate }}">
    <input
      type="text"
      class="w-full"
      pInputText
      [formControl]="form.controls.jdbcDriver"
      placeholder="{{ 'workflow.cubes.enrichment-tool-batch.placeholder.jdbc-driver' | translate }}"
    />
  </selfai-platform-form-field>

  <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool-batch.field-label.jdbc-url' | translate }}">
    <input
      type="text"
      class="w-full"
      pInputText
      [formControl]="form.controls.jdbcUrl"
      placeholder="{{ 'workflow.cubes.enrichment-tool-batch.placeholder.jdbc-url' | translate }}"
    />
  </selfai-platform-form-field>

  <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool-batch.field-label.column' | translate }}">
    <selfai-platform-edit-selection-field [formControl]="form.controls.column"></selfai-platform-edit-selection-field>
  </selfai-platform-form-field>

  <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool-batch.field-label.sql-query' | translate }}">
    <ngx-monaco-editor
      [options]="editorOptions"
      [formControl]="form.controls.sqlQuery"
      class="p-inputtext"
    ></ngx-monaco-editor>
  </selfai-platform-form-field>
</form>
} @else {
<p-message text="{{ 'workflow.cubes.enrichment-tool-batch.no-parameters' | translate }}" severity="error"></p-message>
}
