import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { MenubarModule } from 'primeng/menubar';
import { BreadcrumbsMenuComponent } from '../breadcrumbs-menu';
import { SettingsMenuComponentModule } from '../settings-menu/settings-menu.module';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { HeaderComponent } from './header.component';
import { AiChatComponent } from '../ai-chat';

@NgModule({
  declarations: [HeaderComponent, HeaderLogoComponent],
  exports: [HeaderComponent, AiChatComponent],
  imports: [
    CommonModule,
    MenubarModule,
    AvatarModule,
    SettingsMenuComponentModule,
    TranslateModule,
    BreadcrumbsMenuComponent,
    AiChatComponent,
  ],
})
export class HeaderComponentModule {}
