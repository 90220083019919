import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  ConfigService,
  DestroyService,
  getDataFromLocalStorage,
  KdConfigService,
  LocalStorageKey,
  saveDataToLocalStorage,
  UserProfileService,
  AlertService,
} from '@selfai-platform/shared';
import { from, map, Observable, of, switchMap, takeUntil, zip } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChatMessageFactory } from './ai-chat-message.factory';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SidebarModule } from 'primeng/sidebar';
import { AsyncPipe, JsonPipe, NgClass, NgIf } from '@angular/common';
import { ChatBoxComponent } from '../chat-box';
import { ChatCardComponent } from '../chat-card';
import { ChatSidebarComponent } from '../chat-sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { IndexableType, liveQuery } from 'dexie';
import { Router } from '@angular/router';
import { AiChatApiService, AiChatDomainService, IndexDbChatService } from '../../services';
import {
  AiBotApiChatResponseDTO,
  EAIChatRole,
  EAIMessageType,
  EFrontendActions,
  IAiFrontendAction,
  IAIModel,
  IApiMessage,
  IChat,
  IChatMessage,
} from '../../models';
import { AI_BOT_NAME } from '../../constants';
import { ChatIndexDbService } from '../../services';
import { AiChatService } from '../../services';

@Component({
  selector: 'selfai-platform-ai-chat',
  templateUrl: './ai-chat.component.html',
  styleUrls: ['./ai-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, IndexDbChatService, AiChatApiService],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    ToggleButtonModule,
    SidebarModule,
    NgIf,
    ChatBoxComponent,
    ChatCardComponent,
    ChatSidebarComponent,
    NgClass,
    AsyncPipe,
    InputTextModule,
    ButtonModule,
    RippleModule,
    TranslateModule,
    JsonPipe,
  ],
})
export class AiChatComponent implements OnInit {
  @Input() isVisible: boolean;
  public showAi = true;
  public urls: Record<string, string[]>;
  public version: string;
  public models: IAIModel[];
  public selectedModel: IAIModel;

  public selectedChatId: string | number | IndexableType;
  public selectedChatTitle: string | number;

  public textContent!: string;

  constructor(
    private readonly userProfile: UserProfileService,
    private readonly kdConfig: KdConfigService,
    private readonly configService: ConfigService,
    private readonly aiBotApiService: AiChatApiService,
    private readonly aiChatDomainService: AiChatDomainService,
    private readonly cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly destroy$: DestroyService,
    private readonly indexDbChatService: IndexDbChatService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly aiChatService: AiChatService,
  ) {
    this.configService.configLoaded$.subscribe(() => {
      this.showAi = !!this.kdConfig.getConfig().features?.ollama?.enabled;
    });
  }

  public visible = false;
  public showAllChats = true;
  public chats$: Observable<IChat[]>;
  public messages: IChatMessage[] = [];
  public apiMessages: IApiMessage[] = [];

  public selectChat(chat: IChat): void {
    this.indexDbChatService.activeChat$.next(chat);
  }

  public setChatVisibility(isVisible: boolean): void {
    this.aiChatService.chatVisible$.next(isVisible);
  }

  public async addNewChat(newChatTitle: string): Promise<void> {
    const newChatId = await ChatIndexDbService.chats.add({
      title: newChatTitle,
      createdAt: new Date(),
      updatedAt: new Date(),
      messages: [],
    } as IChat);
    const newChat = await ChatIndexDbService.chats.get({ id: newChatId });
    if (newChat) {
      this.alertService.success(this.translateService.instant('msg.ai-chat.create-chat-success'));
      this.resetChatMessages();
      this.indexDbChatService.activeChat$.next(newChat);

      return;
    } else {
      this.alertService.error(this.translateService.instant('msg.ai-chat.create-chat-errors'));
    }
  }

  public async sendMessage(message: string): Promise<void> {
    if (!message || !message.trim().length) {
      return;
    }

    const newUserMessage = new ChatMessageFactory(message, EAIChatRole.USER, this.getUserName());
    this.messages = [
      ...this.messages,
      newUserMessage.getChatMessage(),
      ChatMessageFactory.getBotTypingMessage(this.translateService.instant('msg.ai-chat.waiting-message')),
    ];
    this.apiMessages = [...this.apiMessages, newUserMessage.getAIMessage()];
    this.textContent = '';

    await ChatIndexDbService.chats
      .where('id')
      .equals(this.selectedChatId)
      .modify((chat) => {
        chat.messages.push(
          newUserMessage.getChatMessage(),
          ChatMessageFactory.getBotTypingMessage(this.translateService.instant('msg.ai-chat.waiting-message')),
        );
        chat.isLoadingMessage = true;
      });
    const updatedChat = await ChatIndexDbService.chats.where('id').equals(this.selectedChatId).first();
    const updatedChatId = updatedChat.id as IndexableType;
    saveDataToLocalStorage(LocalStorageKey.AI_CHAT_MODEL, this.selectedModel.model);

    this.cdr.markForCheck();

    this.saveChat(newUserMessage.getAIMessage())
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (messages: AiBotApiChatResponseDTO[]) => {
        this.messages.pop();

        if (!messages) {
          return;
        }

        const action = messages.find(({ message }) => this.getFrontendAction(message))?.message?.frontendAction;

        if (action) {
          this.processFrontendAction(action);
        }

        const newBotMessages = messages.map(
          ({ message }) => new ChatMessageFactory(message.content, message.role, this.getBotName()),
        );

        if (this.selectedChatId === updatedChatId) {
          this.messages = [...this.messages, ...newBotMessages.map((message) => message.getChatMessage())];
          this.apiMessages = [...this.apiMessages, ...newBotMessages.map((message) => message.getAIMessage())];
        }

        await ChatIndexDbService.chats
          .where('id')
          .equals(updatedChatId)
          .modify((chat) => {
            chat.messages.pop();
            chat.isLoadingMessage = false;
            chat.messages.push(...newBotMessages.map((message) => message.getChatMessage()));
          });
        this.cdr.markForCheck();
      });
  }

  private getFrontendAction(message: IApiMessage): IAiFrontendAction | undefined {
    return message?.frontendAction;
  }

  private processFrontendAction(frontendAction: IAiFrontendAction): void {
    switch (frontendAction.name) {
      case EFrontendActions.NEW_CHART_PREVIEW:
        this.router.navigateByUrl((frontendAction.params as { link: string }).link).then();
    }
  }

  public async removeChat(chat: IChat): Promise<void> {
    await ChatIndexDbService.chats
      .where('id')
      .equals(chat.id as IndexableType)
      .delete();
    this.apiMessages = [];
    this.messages = [];
  }

  public async renameChat(chatToRename: Partial<IChat>): Promise<void> {
    await ChatIndexDbService.chats
      .where('id')
      .equals(chatToRename.id as IndexableType)
      .modify((chat) => {
        chat.title = chatToRename.title;
      });
  }

  private prepareDbOnInit(): Observable<void> {
    return from(ChatIndexDbService.chats.toArray()).pipe(
      switchMap(async (chats) => {
        this.chats$ = from(liveQuery(() => ChatIndexDbService.chats.toArray()));
        for (const chat of chats) {
          await ChatIndexDbService.chats
            .where('id')
            .equals(chat.id as IndexableType)
            .modify((chat) => {
              chat.messages = chat.messages.filter((message) => message.type !== EAIMessageType.SYSTEM);
              chat.isLoadingMessage = false;
            });
        }
      }),
    );
  }

  public ngOnInit(): void {
    zip(this.aiBotApiService.getAiVersion(), this.aiBotApiService.getAiTags(), this.aiBotApiService.getAiUrls())
      .pipe(takeUntil(this.destroy$))
      .subscribe(([{ version }, { models }, urls]) => {
        this.version = version;
        this.models = models;
        this.urls = urls;
        this.selectedModel = this.models.find(
          (model) => model.model === getDataFromLocalStorage<string>(LocalStorageKey.AI_CHAT_MODEL, ''),
        );
        if (!this.selectedModel) {
          this.selectedModel = this.models[0];
        }
      });

    from(this.prepareDbOnInit())
      .pipe(
        switchMap(() => this.indexDbChatService.activeChat$),
        switchMap((activeChat) => {
          if (!activeChat) {
            return from(ChatIndexDbService.chats.toArray()).pipe(
              map((chats) => {
                if (chats.length) {
                  return chats[0];
                }
                return null;
              }),
            );
          } else {
            return from(ChatIndexDbService.chats.get({ id: activeChat.id }));
          }
        }),
        switchMap((chat) => {
          if (chat) {
            this.selectedChatId = chat.id;
            this.selectedChatTitle = chat.title;
            this.setMessages(chat.messages);
          }

          return of(null);
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private setMessages(messages: IChatMessage[]) {
    this.messages = messages;
    if (this.messages.length) {
      this.apiMessages = ChatMessageFactory.convertChatMessagesToApiMessages(this.messages);
    }
    this.cdr.markForCheck();
  }

  private saveChat(message: IApiMessage): Observable<any> {
    if (!this.messages.length) {
      return this.aiChatDomainService.saveChatCompletions(message, this.selectedModel);
    } else {
      return this.aiChatDomainService.saveChat(this.apiMessages, this.selectedModel);
    }
  }

  private getUserName(): string {
    const { firstName, lastName } = this.userProfile.value;
    return firstName?.length && lastName?.length
      ? `${firstName} ${lastName}`
      : this.translateService.instant('msg.ai-chat.current-user');
  }

  private getBotName(): string {
    return AI_BOT_NAME;
  }

  private resetChatMessages(): void {
    this.messages = [];
    this.apiMessages = [];
  }
}
