import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
  AfterContentInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import {
  BoardConfiguration,
  BoardDataSource,
  ChartType,
  CustomField,
  Dashboard,
  DashboardDomainService,
  DashboardWidgetRelation,
  Datasource,
  DatasourceField,
  Filter,
  FilterWidget,
  FilterWidgetConfiguration,
  LayoutMode,
  PageWidget,
  PageWidgetConfiguration,
  Pivot,
  RecycleBinApiToDomainService,
  TextWidget,
  WIDGET_CONTEXT_ID,
  Widget,
  WidgetConfiguration,
  WidgetShowType,
  Workbook,
  WorkbookActionsService,
  createFilterWidget,
  createFilterWidgetConfiguration,
  createPageWidget,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';

import { Modal } from '../common/domain/modal';
import { SubscribeArg } from '../common/domain/subscribe-arg';
import { EventBroadcaster } from '../common/event/event.broadcaster';
import { ImageService } from '../common/service/image.service';
import { PopupService } from '../common/service/popup.service';
import { CommonUtil } from '../common/util';
import { DatasourceService } from '../datasource/service/datasource.service';

import { DashboardFiltersService } from '@selfai-platform/bi-chart-engine';
import {
  DashboardLayoutFilterService,
  createConfigurationFromKtdGridLayout,
  initializeBoardFilters,
} from './component/dashboard-layout/dashboard-layout';
import { DashboardLayoutComponent } from './component/dashboard-layout/dashboard.layout.component';
import { DatasourcePanelComponent } from './component/update-dashboard/datasource-panel.component';
import { PageRelationComponent } from './component/update-dashboard/page-relation.component';
import { TextWidgetPanelComponent } from './component/update-dashboard/text-widget-panel.component';
import { ConfigureFiltersComponent } from './filters/configure-filters.component';
import { UpdateDashboardOptions } from './service';
import { DashboardApiLegacyService } from './service';
import { WidgetService } from './service';
import {
  Hierarchy,
  RightTab,
  getChartFieldsFromWidget,
  syncCustomFieldInWidgetPivot,
  syncCustomFieldInWidgetShelf,
  syncDatasourceAliasInWidgetPivot,
} from './update-dashboard';
import { convertPageWidgetSpecToServer, getMainDataSources, isSameDataSource } from './util';
import { DashboardUtil } from './util';
import { FilterUtil } from './util';
import { IChartPreviewData } from '@selfai-platform/shell';

@Component({
  selector: 'selfai-bi-update-dashboard',
  templateUrl: './update-dashboard.component.html',
  providers: [
    DashboardLayoutFilterService,
    {
      provide: WIDGET_CONTEXT_ID,
      useValue: 'dashboard-update',
    },
  ],
  styleUrls: ['update-dashboard.component.scss'],
})
export class UpdateDashboardComponent extends DashboardLayoutComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  @ViewChild(DatasourcePanelComponent)
  private datasourcePanelComp: DatasourcePanelComponent;

  @ViewChild(TextWidgetPanelComponent)
  private _textWidgetsPanelComp: TextWidgetPanelComponent;

  @ViewChild(PageRelationComponent, { static: true })
  private _pageRelationComp: PageRelationComponent;

  @ViewChild(ConfigureFiltersComponent, { static: true })
  private _configFilterComp: ConfigureFiltersComponent;

  @Input()
  public workbook: Workbook;

  @Input()
  public dashboards: Dashboard[] = [];

  @Input()
  public dashboard: Dashboard;

  @Input()
  public startupCmd: { cmd: string; id?: string; type?: string };

  @Output()
  public selectedDashboard: EventEmitter<Dashboard> = new EventEmitter();

  @Output()
  public createDashboard = new EventEmitter<void>();

  @Output()
  public updateComplete: EventEmitter<Dashboard> = new EventEmitter();

  public fields: DatasourceField[];

  public selectedRightTab: RightTab = RightTab.CHART;

  public rightTab = RightTab;

  public isSearchMode = false;
  public searchText = '';

  public deleteWidgetIds: string[] = [];

  public selectedPageWidget: PageWidget;

  public hierarchy: Hierarchy;

  public chartFilters: Filter[] = [];

  public isShowDetailMenu = false;
  public isShowDashboardList = false;
  public isAppendLayout = false;
  public isUpdateDataSource = false;
  public isShowPage = false;
  public isChangeDataSource = false;
  public isDashboardNameEditMode = false;
  public isDashboardDescriptionEditMode = false;

  public orgBoardInfo: Dashboard;

  public openIndexFilterPanel = 0;

  public droppedData = [];

  public editableName: string;
  public editableDescription: string;

  get pageWidgets(): PageWidget[] {
    return DashboardUtil.getPageWidgets(this.dashboard);
  }

  get boardFilters(): Filter[] {
    return DashboardUtil.getBoardFilters(this.dashboard);
  }

  get pageFilters(): FilterWidget[] {
    return DashboardUtil.getFilterWidgets(this.dashboard);
  }

  get boardConfiguration(): BoardConfiguration {
    return DashboardUtil.getBoardConfiguration(this.dashboard);
  }

  readonly #translateService = inject(TranslateService);

  constructor(
    public imageService: ImageService,
    protected broadCaster: EventBroadcaster,
    protected dashboardApiService: DashboardApiLegacyService,
    protected widgetService: WidgetService,
    protected datasourceService: DatasourceService,
    protected popupService: PopupService,
    protected appRef: ApplicationRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected elementRef: ElementRef,
    protected activatedRoute: ActivatedRoute,
    protected injector: Injector,
    protected dashboardDomainService: DashboardDomainService,
    private readonly dashboardFiltersService: DashboardFiltersService,
    private readonly recycleBinService: RecycleBinApiToDomainService,
    private readonly workbookActionsService: WorkbookActionsService,
    protected router: Router,
  ) {
    super(
      broadCaster,
      widgetService,
      datasourceService,
      popupService,
      appRef,
      componentFactoryResolver,
      elementRef,
      injector,
      imageService,
      dashboardApiService,
    );
  }

  public ngOnInit() {
    super.ngOnInit();

    this.subscriptions.push(
      this.broadCaster.on<never>('UPDATE_BOARD_UPDATE_DATASOURCE').subscribe(() => {
        this.isUpdateDataSource = true;
        this.isShowPage = false;
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<{ widgetId: string; value: string }>('WIDGET_CHANGE_TITLE').subscribe((data) => {
        const widget = DashboardUtil.getWidget(this.dashboard, data.widgetId);
        if (widget) {
          widget.name = data.value;
          this.hierarchy.modify(widget);
        }
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<{ widgetId: string; mode: boolean }>('TOGGLE_TITLE').subscribe((data) => {
        this.dashboard.configuration.options.widget.showTitle = WidgetShowType.BY_WIDGET;
        this.dashboard = DashboardUtil.setVisibleWidgetTitle(this.dashboard, data.widgetId, data.mode);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<never>('TOGGLE_LEGEND').subscribe(() => {
        this.dashboard.configuration.options.widget.showLegend = WidgetShowType.BY_WIDGET;
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<never>('TOGGLE_MINIMAP').subscribe(() => {
        this.dashboard.configuration.options.widget.showMinimap = WidgetShowType.BY_WIDGET;
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<{ widgetId: string }>('TOGGLE_SYNC').subscribe((data) => {
        const widgetInfo = DashboardUtil.getWidget(this.dashboard, data.widgetId);
        const widgetConf = widgetInfo.configuration as PageWidgetConfiguration;
        widgetConf.sync = false === widgetConf.sync;
        DashboardUtil.updateWidget(this.dashboard, widgetInfo);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<{ widgetId: string }>('COPY_WIDGET').subscribe((data) => {
        this.copyWidgetEventHandler(data);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<{ widgetId: string }>('EDIT_WIDGET').subscribe((data) => {
        this.editWidgetEventHandler(data.widgetId);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<{ id: string }>('MOVE_EDIT_WIDGET').subscribe((data) => {
        this.editWidgetEventHandler(data.id);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<{ widgetId: string }>('REMOVE').subscribe((data) => {
        this.removeWidgetComponent(data.widgetId);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<{ widget: Widget; filter: Filter }>('CHANGE_FILTER_SELECTOR').subscribe((data) => {
        this.dashboard = DashboardUtil.updateWidget(this.dashboard, data.widget);
        this.dashboard = DashboardUtil.updateBoardFilter(this.dashboard, data.filter)[0];
      }),
    );

    this.selectedRightTab = RightTab.CHART;

    const popupSubscribe = this.popupService.view$.subscribe((data: SubscribeArg) => {
      this.isShowPage = false;
      this.selectedPageWidget = null;

      if ('modify-page-close' !== data.name) {
        let alertMsg = '';
        const changeWidgetData: PageWidget = <PageWidget>data.data;
        if ('create-page-complete' === data.name) {
          const pageWidget: PageWidget = this.createPageWidget(changeWidgetData, this.isAppendLayout);

          this.hierarchy.add(pageWidget);
          this.dashboard = DashboardUtil.setDashboardConfRelations(
            this.dashboard,
            this.hierarchy.get().map((node) => node.toPageRelation()),
          );

          alertMsg = this.translateService.instant('msg.board.alert.create.chart.success');
        } else if ('modify-page-complete' === data.name) {
          const pageWidget: PageWidget = this.modifyPageWidget(changeWidgetData, true);

          this.hierarchy.modify(pageWidget);

          alertMsg = this.translateService.instant('msg.board.alert.update.chart.success');
        }

        const customFields: CustomField[] = changeWidgetData.configuration.customFields;
        this._syncWidgetsAndFilters(customFields, DashboardUtil.getFields(this.dashboard), changeWidgetData.id);

        this.alertPrimeService.success(alertMsg);

        this.renderLayout();

        this.dashboard.updateId = CommonUtil.getUUID();

        this.hideBoardLoading();
      }

      this.isAppendLayout = false;

      this.safelyDetectChanges();
    });

    this.subscriptions.push(popupSubscribe);
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    this._initViewPage();
  }

  public ngAfterContentInit() {
    const chartType = this.activatedRoute.snapshot.queryParams['chartType'];
    if (chartType) {
      this.createPreviewChart(this.activatedRoute.snapshot.queryParams as IChartPreviewData);
    }
  }

  public createPreviewChart(data: IChartPreviewData): void {
    this.addChart();
    if (data?.chartType) {
      this.selectedPageWidget.configuration.chart.type = data.chartType as ChartType;
    }
    if (data?.datasourceId) {
      setTimeout(() => {
        this.selectedPageWidget.configuration.dataSource = DashboardUtil.findDataSourceOnBoard(this.dashboard, {
          id: data.datasourceId,
        });
      });
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public toggleEditDashboardName(): void {
    this.editableName = this.dashboard.name;
    this.isDashboardNameEditMode = !this.isDashboardNameEditMode;
    this.isDashboardDescriptionEditMode = false;
  }

  public toggleEditDashboardDescription(): void {
    this.editableDescription = this.dashboard.description;
    this.isDashboardDescriptionEditMode = !this.isDashboardDescriptionEditMode;
    this.isDashboardNameEditMode = false;
  }

  public setDashboardName(name: string): void {
    if (!name) {
      return;
    }
    this.dashboard.name = name;
  }

  public setDashboardDescription(description: string): void {
    this.dashboard.description = description;
  }

  public isTimeFilter(filter: Filter): boolean {
    return FilterUtil.isTimeFilter(filter);
  }

  public editWidgetEventHandler(widgetId: string) {
    const widget: Widget = DashboardUtil.getWidget(this.dashboard, widgetId);
    switch (widget.type) {
      case 'page':
        {
          widget.dashBoardId = this.dashboard.id;

          const pageWidgetConf = widget.configuration as PageWidgetConfiguration;
          if (pageWidgetConf.filters) {
            pageWidgetConf.filters.forEach((filter) => {
              if (!filter['clzField']) {
                filter['clzField'] = DashboardUtil.getFieldByName(this.dashboard, filter.dataSource, filter.field);
              }
            });
          }

          this.selectedPageWidget = <PageWidget>widget;
          this.isShowPage = true;
        }

        break;
      case 'filter':
        {
          const filterWidgetConf: FilterWidgetConfiguration = <FilterWidgetConfiguration>widget.configuration;
          this.openUpdateFilterPopup(filterWidgetConf.filter);
        }

        break;
      case 'text':
        this.openTextWidgetEditor(<TextWidget>widget);
        break;
    }
  }

  public copyWidgetEventHandler(data: { widgetId: string }) {
    this.showBoardLoading();
    const srcWidgetInfo = DashboardUtil.getWidget(this.dashboard, data.widgetId);
    const newWidgetInfo: PageWidget = <PageWidget>_.cloneDeep(srcWidgetInfo);
    delete newWidgetInfo.id;
    newWidgetInfo.name = srcWidgetInfo.name + '_copy';
    const board: Dashboard = this.dashboard;
    this.widgetService
      .createWidget(newWidgetInfo, board.id)
      .then((resWidgetInfo: any) => {
        const pageWidget: PageWidget = _.extend(
          createPageWidget({
            ...resWidgetInfo,
            dashBoardId: this.dashboard.id,
          }),
        );
        this.dashboard = DashboardUtil.addWidget(this.dashboard, pageWidget, true);
        this.hierarchy.add(pageWidget);
        this.appendWidgetInLayout([pageWidget]);
        this.hideBoardLoading();
        this.safelyDetectChanges();
      })
      .catch((err) => {
        this.commonExceptionHandler(err);
      });
  }

  public addWidgetEventHandler(widgetId: string) {
    this.dashboard = DashboardUtil.setUseWidgetInLayout(this.dashboard, widgetId, true);
    this.renderLayout();
  }

  public onLayoutInitialised() {
    this.changeDetect.detectChanges();
  }

  public execBeforeUnload(): boolean {
    let orgInfo: Dashboard = _.cloneDeep(this.orgBoardInfo);
    let currInfo: Dashboard = _.cloneDeep(this.dashboard);

    const removeKeys: string[] = [
      'createdBy',
      'createdTime',
      'dataSources',
      'modifiedBy',
      'modifiedTime',
      '_links',
      'workBook',
    ];
    removeKeys.forEach((key) => {
      delete orgInfo[key];
      delete currInfo[key];
    });
    const convertSpec = (item) => {
      if ('page' === item.type) {
        item.configuration = convertPageWidgetSpecToServer(item.configuration);
      } else if ('filter' === item.type) {
        item.configuration['filter'] = FilterUtil.convertToServerSpecForDashboard(item.configuration['filter']);
      }
      delete item['dashBoard'];
      return item;
    };
    orgInfo.widgets = orgInfo.widgets.map(convertSpec);
    currInfo.widgets = currInfo.widgets.map(convertSpec);
    orgInfo = this.dashboardApiService.convertSpecToServer(orgInfo);
    currInfo = this.dashboardApiService.convertSpecToServer(currInfo);

    this.useUnloadConfirm = JSON.stringify(orgInfo) !== JSON.stringify(currInfo);

    return this.useUnloadConfirm;
  }

  public addChart() {
    this.selectedRightTab = RightTab.CHART;
    this.safelyDetectChanges();
    this.selectedPageWidget = this.getNewPageWidget();
    this.isShowPage = true;
  }

  public openTextWidgetEditor(widget?: TextWidget) {
    this.selectedRightTab = RightTab.TEXT;
    this.safelyDetectChanges();
    if (widget) {
      this._textWidgetsPanelComp.modifyWidget(widget);
    } else {
      this._textWidgetsPanelComp.addWidget();
    }
  }

  public openUpdateFilterPopup(filter?: Filter) {
    this.selectedRightTab = RightTab.FILTER;
    this.safelyDetectChanges();
    this._configFilterComp.open(this.dashboard, this.chartFilters, filter);
  }

  public setTextWidget(event: { name: string; widget: TextWidget }) {
    if ('CREATE' === event.name) {
      this.showBoardLoading();
      this.widgetService.createWidget(event.widget, this.dashboard.id).then(
        (result) => {
          const textWidget: TextWidget = _.merge(event.widget, result);
          this.dashboard = DashboardUtil.addWidget(this.dashboard, textWidget, this.isAppendLayout);
          this.dashboard.updateId = CommonUtil.getUUID();
          this.renderLayout();
          this.hideBoardLoading();
          this.isAppendLayout = false;
          this.safelyDetectChanges();
        },
        (error) => {
          this.hideBoardLoading();
          this.alertPrimeService.error(error.message);
        },
      );
    } else if ('DELETE' === event.name) {
      this.isAppendLayout = false;
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.comm.ui.del.description');
      modal.btnName = this.translateService.instant('msg.comm.btn.del');
      modal.data = { type: 'removeTextWidget' };
      modal.afterConfirm = () => {
        this.deleteWidgetIds.push(event.widget.id);
        this.removeWidget(event.widget.id);
        this.dashboard.updateId = CommonUtil.getUUID();
        this.safelyDetectChanges();
      };
      CommonUtil.confirm(modal);
    } else {
      this.isAppendLayout = false;
      const textWidget: TextWidget = event.widget as TextWidget;
      this.dashboard = DashboardUtil.updateWidget(this.dashboard, textWidget);
      this.reloadWidget(textWidget);
      this.renderLayout();
    }
  }

  public removeWidget(widgetId: string) {
    this.recycleBinService.recycleBinWidget(widgetId).subscribe();

    this.removeWidgetComponent(widgetId);
    this.dashboard = DashboardUtil.removeWidget(this.dashboard, widgetId);
  }

  public getNewPageWidget(): PageWidget {
    const pageWidget: PageWidget = createPageWidget({
      dashBoardId: this.dashboard.id,
      name: this.#translateService.instant('msg.board.chart.newDiagram'),
    });
    const board: Dashboard = this.dashboard;
    pageWidget.dashBoardId = this.dashboard.id;
    (<PageWidgetConfiguration>pageWidget.configuration).dataSource = DashboardUtil.getFirstBoardDataSource(board);
    (<PageWidgetConfiguration>pageWidget.configuration).customFields = board.configuration.customFields;
    return pageWidget;
  }

  public createPageWidget(pageWidget: PageWidget, isAppendLayout: boolean): PageWidget {
    if (
      this.dashboard.configuration &&
      this.dashboard.configuration.filters &&
      this.dashboard.configuration.filters.length > 0
    ) {
      this.dashboard = DashboardUtil.setBoardFilters(this.dashboard, this.dashboard.configuration.filters);
    }

    if (this.dashboard && this.dashboard.configuration) {
      this.dashboard = DashboardUtil.setCustomFields(this.dashboard, this.dashboard.configuration.customFields);
    }

    this.dashboard = DashboardUtil.addWidget(this.dashboard, pageWidget, isAppendLayout);

    return pageWidget;
  }

  public modifyPageWidget(pageWidget: PageWidget, isReload: boolean): PageWidget {
    if (
      this.dashboard.configuration &&
      'filters' in this.dashboard.configuration &&
      this.dashboard.configuration.filters.length > 0
    ) {
      this.dashboard = DashboardUtil.setBoardFilters(this.dashboard, this.dashboard.configuration.filters);
    }

    if (this.dashboard && this.dashboard.configuration) {
      this.dashboard = DashboardUtil.setCustomFields(this.dashboard, this.dashboard.configuration.customFields);
    }

    this.dashboard = DashboardUtil.updateWidget(this.dashboard, pageWidget);
    if (isReload) {
      this.reloadWidget(pageWidget);
    }

    return pageWidget;
  }

  public moveOrNewDashboard(dashboardItem?: Dashboard) {
    this.execBeforeUnload();
    if (this.useUnloadConfirm) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.board.alert.title.change');
      modal.description = this.translateService.instant('msg.board.alert.desc.move');
      modal.btnName = this.translateService.instant('msg.comm.btn.mov');
      modal.data = { type: 'changeDashboard' };
      modal.afterConfirm = () => {
        if (dashboardItem) {
          this.selectedDashboard.emit(dashboardItem);
        } else {
          this.createDashboard.emit();
        }
      };
      CommonUtil.confirm(modal);
    } else {
      if (dashboardItem) {
        this.selectedDashboard.emit(dashboardItem);
      } else {
        this.createDashboard.emit();
      }
    }
  }

  public toggleRnb(menu: RightTab) {
    if (menu === this.selectedRightTab) {
      this.selectedRightTab = RightTab.NONE;
    } else {
      this.selectedRightTab = menu;
    }

    this.updateLayoutSize();
  }

  public updateDashboard() {
    this.showBoardLoading();

    from(this.saveWidgetsBeforeSaveDashboard())
      .pipe(
        switchMap(() => {
          return from(
            this.callUpdateDashboardService({
              name: this.dashboard.name,
              description: this.dashboard.description,
              imageUrl: null,
            }),
          );
        }),
      )
      .subscribe({
        next: (res) => {
          this.goToViewDashboard();
        },
        error: (error) => {
          if (error.message) {
            this.alertPrimeService.error(this.translateService.instant(error.message));
          } else {
            this.alertPrimeService.error(this.translateService.instant('msg.board.alert.widget.apply.error'));
          }

          this.hideBoardLoading();
        },
        complete: () => {
          this.hideBoardLoading();
        },
      });
  }

  goToViewDashboard(): void {
    this.workbookActionsService.goToDashboardView(this.workbook.id, this.dashboard.id);
  }

  private async saveWidgetsBeforeSaveDashboard() {
    const promises = [];

    DashboardUtil.getWidgets(this.dashboard).forEach((result: Widget) => {
      if (this.deleteWidgetIds.indexOf(result.id) === -1) {
        const param = { configuration: _.cloneDeep(result.configuration), name: result.name };
        switch (result.type) {
          case 'page':
            {
              if (-1 < this._removeDsEngineNames.indexOf(param.configuration['dataSource']['engineName'])) {
                this.hierarchy.del(result.id);
                this.dashboard.configuration.relations = this.hierarchy.get().map((node) => node.toPageRelation());
                this.deleteWidgetIds.push(result.id);
                this.removeWidgetComponent(result.id);
              } else {
                param.configuration = convertPageWidgetSpecToServer(param.configuration as PageWidgetConfiguration);
                promises.push(this.widgetService.updateWidget(result.id, param));
              }
            }
            break;
          case 'filter':
            {
              if (-1 < this._removeDsEngineNames.indexOf(param.configuration['filter']['dataSource'])) {
                this.deleteWidgetIds.push(result.id);
                this.removeWidgetComponent(result.id);
              } else {
                param.configuration['filter'] = FilterUtil.convertToServerSpecForDashboard(
                  param.configuration['filter'],
                );
                promises.push(this.widgetService.updateWidget(result.id, param));
              }
            }
            break;
          case 'text': {
            promises.push(this.widgetService.updateWidget(result.id, param));
          }
        }
      }
    });

    if (this.deleteWidgetIds.length > 0) {
      this.deleteWidgetIds.forEach((id) => promises.push(this.widgetService.deleteWidget(id)));
    }
    if (promises.length > 0) {
      try {
        return await Promise.all(promises);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }

  public openDismissConfirm() {
    this.execBeforeUnload();
    if (this.useUnloadConfirm) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.board.alert.title.change');
      modal.description = this.translateService.instant('msg.board.alert.desc.exit');
      modal.btnName = this.translateService.instant('msg.comm.btn.exit');
      modal.data = { type: 'dismiss' };
      modal.afterConfirm = () => {
        this.goToViewDashboard();
      };
      CommonUtil.confirm(modal);
    } else {
      this.goToViewDashboard();
    }
  }

  public toggleDatasourcePanel() {
    if (RightTab.NONE === this.selectedRightTab) {
      this.toggleRnb(RightTab.CHART);
      this.changeDetect.detectChanges();
    }
    this.datasourcePanelComp.toggleDatasourcePanel();
  }

  public closeUpdateDataSource() {
    this.isUpdateDataSource = false;
  }

  public changeDataSource(dashboard: Dashboard) {
    this.dashboard = dashboard;

    this.dashboard.configuration.filters = _.cloneDeep(this.dashboard.configuration.filters).filter((filter) => {
      if (dashboard.dataSources.some((ds) => ds.engineName === filter.dataSource)) {
        return true;
      } else {
        const filterWidget: FilterWidget = DashboardUtil.getFilterWidgetByFilter(dashboard, filter);
        if (filterWidget) {
          this.deleteWidgetIds.push(filterWidget.id);
          this.removeWidget(filterWidget.id);
        }
        return false;
      }
    });

    _.cloneDeep(this.dashboard.widgets).forEach((widget) => {
      if ('page' !== widget.type) {
        return true;
      } else {
        const pageConf: PageWidgetConfiguration = <PageWidgetConfiguration>widget.configuration;
        if (dashboard.dataSources.some((ds) => isSameDataSource(pageConf.dataSource, ds))) {
          return true;
        } else {
          this.hierarchy.del(widget.id);
          this.dashboard = DashboardUtil.setDashboardConfRelations(
            this.dashboard,
            this.hierarchy.get().map((node) => node.toPageRelation()),
          );
          this.deleteWidgetIds.push(widget.id);
          this.removeWidget(widget.id);
          return false;
        }
      }
    });

    this.dashboard.configuration.fields = [];

    this._runDashboard(this.dashboard);
    this.updateDashboard();

    this.isChangeDataSource = true;
    this.isUpdateDataSource = false;
  }

  public showSetPageRelation() {
    this._pageRelationComp.run(this.hierarchy.get(), DashboardUtil.getPageWidgets(this.dashboard));
  }

  public changePageWidgetRelation(widgetRels: DashboardWidgetRelation[]) {
    if (widgetRels) {
      this.hierarchy.set(widgetRels);
      this.dashboard = DashboardUtil.setDashboardConfRelations(
        this.dashboard,
        widgetRels.map((node) => node.toPageRelation()),
      );

      this.reloadAllWidgets();
    }
  }

  public getWidgetType(widgetId: string): string {
    const widget = DashboardUtil.getWidget(this.dashboard, widgetId);
    if (widget) {
      let strType: string = widget.type;
      if ('page' === widget.type) {
        strType = (<PageWidgetConfiguration>widget.configuration).chart.type.toString();
      }
      return strType;
    } else {
      return '';
    }
  }

  public endDrop($event: CdkDragDrop<string>) {
    const widgetId = $event.item.data as string;
    this.addWidgetEventHandler(widgetId);
  }

  public setRemoveWidget(widgetId: string) {
    if (this.hierarchy.isLeaf(widgetId)) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.comm.ui.del.description');
      modal.btnName = this.translateService.instant('msg.comm.btn.del');
      modal.data = { type: 'removePageWidget' };
      modal.afterConfirm = () => {
        this.hierarchy.del(widgetId);
        this.dashboard = DashboardUtil.setDashboardConfRelations(
          this.dashboard,
          this.hierarchy.get().map((node) => node.toPageRelation()),
        );
        this.deleteWidgetIds.push(widgetId);
        this.removeWidget(widgetId);

        this.dashboard.updateId = CommonUtil.getUUID();
        this.safelyDetectChanges();
      };
      CommonUtil.confirm(modal);
    } else {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.remove-not-parent'));
    }
  }

  public updateFieldAndWidgetPivot(changeField: DatasourceField, isReload: boolean = false) {
    this.dashboard = DashboardUtil.updateField(this.dashboard, changeField);
    this._changeFieldAlias(changeField, isReload);
  }

  public updateFieldAndWidgetPivotAndRender(changeField: DatasourceField) {
    this.updateFieldAndWidgetPivot(changeField, true);
  }

  public getChartFields(widgetId: string): string {
    const widget: Widget = DashboardUtil.getWidget(this.dashboard, widgetId);
    return getChartFieldsFromWidget(widget);
  }

  public filterListTrackByFn(index, filterWidget: FilterWidget) {
    const filter: Filter = (<FilterWidgetConfiguration>filterWidget.configuration).filter;
    return filter.dataSource + filter.type + filter.field;
  }

  public isDraggableFilterWidget(item: FilterWidget): boolean {
    const filter: Filter = this.getFilterForFilterWidget(item);
    if (!filter.ui) {
      filter.ui = {
        importanceType: 'general',
      };
    }
    return !filter.ui.filteringSeq && !filter.ui.widgetId && !this.isWidgetInLayout(item.id);
  }

  public getFilterForFilterWidget(item: FilterWidget): Filter {
    return (<FilterWidgetConfiguration>item.configuration).filter;
  }

  public changeBoardConf(boardConf: BoardConfiguration) {
    this.refreshLayout(boardConf);
  }

  public updateCustomField(data: { customField: CustomField; isEdit: boolean }) {
    this.showBoardLoading();

    const customField: CustomField = data.customField;
    const isEdit: boolean = data.isEdit;

    const customFields: CustomField[] = DashboardUtil.getCustomFields(this.dashboard);

    if (!isEdit) {
      customFields.push(customField);
    } else {
      customFields.forEach((field) => {
        if (field.name === customField.oriColumnName) {
          field.alias = customField.alias;
          field.name = customField.name;
          field.expr = customField.expr;
        }
      });
    }

    this.dashboardApiService
      .updateDashboard(this.dashboard.id, { configuration: DashboardUtil.getBoardConfiguration(this.dashboard) })
      .then((result) => {
        if ('configuration' in result) {
          result = DashboardUtil.convertSpecToUI(result);
          this.dashboard = DashboardUtil.updateBoardConfiguration(this.dashboard, result.configuration);
          this.dashboard.updateId = CommonUtil.getUUID();
          this.broadCaster.broadcast('SET_CUSTOM_FIELDS', { customFields: customFields });
          if (isEdit) {
            this.alertPrimeService.success(
              this.translateService.instant('msg.board.custom.ui.update', { name: customField.name }),
            );
          } else {
            this.alertPrimeService.success(
              this.translateService.instant('msg.board.custom.ui.create', { name: customField.name }),
            );
          }
          this.hideBoardLoading();
        }
        this.safelyDetectChanges();
      })
      .catch((err) => this.commonExceptionHandler(err));
  }

  public deleteCustomField(field: CustomField) {
    const useChartList: string[] = [];
    const useFilterList: string[] = [];

    const widgets = DashboardUtil.getPageWidgets(this.dashboard);

    if (widgets && widgets.length > 0) {
      widgets.forEach((widget: Widget) => {
        if (
          widget.configuration &&
          'pivot' in widget.configuration &&
          'columns' in (widget.configuration['pivot'] as Pivot)
        ) {
          const idx = _.findIndex(widget.configuration['pivot']['columns'], { name: field.name });
          if (idx > -1) useChartList.push(widget.name);
        }

        if (
          widget.configuration &&
          'pivot' in widget.configuration &&
          'aggregations' in (widget.configuration['pivot'] as Pivot)
        ) {
          const idx = _.findIndex(widget.configuration['pivot']['aggregations'], { name: field.name });
          if (idx > -1) useChartList.push(widget.name);
        }
        if (
          widget.configuration &&
          'pivot' in widget.configuration &&
          'rows' in (widget.configuration['pivot'] as Pivot)
        ) {
          const idx = _.findIndex(widget.configuration['pivot']['rows'], { name: field.name });
          if (idx > -1) useChartList.push(widget.name);
        }
      });
    }

    let idx = _.findIndex(this.chartFilters, { field: field.name });
    if (idx > -1) useFilterList.push(this.chartFilters[idx].type + '_' + this.chartFilters[idx].field);

    const boardFilter: Filter = DashboardUtil.getBoardFilter(this.dashboard, field);
    boardFilter && useFilterList.push(boardFilter.type + '_' + boardFilter.field);

    if (useFilterList.length > 0 || useChartList.length > 0) {
      let description = '';
      if (useFilterList.length > 0 && useChartList.length > 0) {
        description =
          "'" +
          useChartList.join("' , '") +
          "','" +
          useFilterList.join("' , '") +
          "' " +
          this.translateService.instant('msg.board.ui.use.chart.filter');
      } else if (useChartList.length > 0) {
        description = "'" + useChartList.join("' , '") + "' " + this.translateService.instant('msg.board.ui.use.chart');
      } else if (useFilterList.length > 0) {
        description =
          "'" + useFilterList.join("' , '") + "' " + this.translateService.instant('msg.board.ui.use.filter');
      }

      const modal = new Modal();
      modal.name = this.translateService.instant('msg.board.ui.not.delete.custom');
      modal.description = description;
      modal.isShowCancel = false;
      modal.data = { type: 'deleteCustomField' };
      CommonUtil.confirm(modal);
      return;
    }

    const customFields = DashboardUtil.getCustomFields(this.dashboard);

    idx = _.findIndex(customFields, { name: field.name });

    if (idx > -1) {
      customFields.splice(idx, 1);

      this.dashboard = DashboardUtil.setCustomFields(this.dashboard, customFields);
      this.dashboard.updateId = CommonUtil.getUUID();
      this.safelyDetectChanges();

      this.broadCaster.broadcast('SET_CUSTOM_FIELDS', { customFields: customFields });

      this.hideBoardLoading();
    }
  }

  public updateFilter(filter: Filter, isSetPanel: boolean = false) {
    this.showBoardLoading();

    if (isNullOrUndefined(filter) || isNullOrUndefined(filter.type)) {
      return;
    }

    this._changeChartFilterToGlobalFilter(filter);

    const updateResult: [Dashboard, boolean] = DashboardUtil.updateBoardFilter(this.dashboard, filter, true);
    this.dashboard = updateResult[0];

    this._organizeAllFilters(true).then(() => {
      this._syncFilterWidget();

      if (updateResult[1]) {
        this.openIndexFilterPanel = DashboardUtil.getFilterWidgets(this.dashboard).length - 1;
      }

      this._configFilterComp.close();
      this.hideBoardLoading();
      if (isSetPanel) {
        this.popupService.notiFilter({ name: 'change-filter', data: filter });
      }
      this.safelyDetectChanges();
    });
  }

  public configureFilter(filter: Filter) {
    if (DashboardUtil.isNewFilter(this.dashboard, filter)) {
      this.addFilter(filter, () => {
        this.updateFilter(filter, true);
      });
    } else {
      this.updateFilter(filter, true);
    }
  }

  public addFilter(filter: Filter, callback?: () => void) {
    if (!filter.ui.widgetId) {
      this.showBoardLoading();
      const newFilterWidget: FilterWidget = createFilterWidget(filter, this.dashboard.id);
      this.widgetService.createWidget(newFilterWidget, this.dashboard.id).then((result) => {
        this.dashboard = DashboardUtil.addWidget(this.dashboard, _.merge(newFilterWidget, result));

        filter['isNew'] = true;
        this.dashboard = DashboardUtil.addBoardFilter(this.dashboard, filter);

        callback && callback();

        this.safelyDetectChanges();

        this.renderLayout();

        this.dashboard.updateId = CommonUtil.getUUID();

        this.hideBoardLoading();
      });
    }
  }

  public deleteFilter(filter: Filter) {
    if (filter.ui.widgetId) {
      const widget: PageWidget = DashboardUtil.getWidget(this.dashboard, filter.ui.widgetId) as PageWidget;

      if (widget) {
        _.remove(widget.configuration.filters, { field: filter.field });

        const removeIdx: number = this.chartFilters.findIndex(
          (item) => item.field === filter.field && item.ui.widgetId === filter.ui.widgetId,
        );
        this.chartFilters.splice(removeIdx, 1);

        this._syncFilterWidget();
        this.popupService.notiFilter({ name: 'remove-filter', data: filter });
      }
    } else {
      const filterWidget: FilterWidget = DashboardUtil.getFilterWidgetByFilter(this.dashboard, filter);
      if (filterWidget) {
        this.deleteWidgetIds.push(filterWidget.id);
        this.removeWidget(filterWidget.id);
      }

      this.dashboard = DashboardUtil.deleteBoardFilter(this.dashboard, filter);
      this.dashboard.updateId = CommonUtil.getUUID();
      this.safelyDetectChanges();
    }

    this._syncFilterWidget();
    this.popupService.notiFilter({ name: 'remove-filter', data: filter });
  }

  public changeFilter(field: DatasourceField) {
    field.useFilter = !field.useFilter;
  }

  public openChangeFilterConfirm(filter: Filter) {
    const modal = new Modal();
    modal.name = this.translateService.instant('msg.board.filter.alert.change.global');
    modal.description = this.translateService.instant('msg.board.filter.alert.change.global.des');
    modal.data = { filter, type: 'changeFilter' };
    CommonUtil.confirm(modal);
  }

  public closeComponent(target: string) {
    switch (target) {
      case 'UPDATE-FILTER':
        this._configFilterComp.close();
        break;
      case 'PAGE':
        this.isShowPage = false;
        break;
    }
    this.changeDetect.detectChanges();
  }

  public navigateToEditDashboardView(dashboardId: string): void {
    this.router.navigate(['bi/workbook', this.workbook.id, 'edit', dashboardId]).then(() => {
      window.location.reload();
    });
  }

  private _changeChartFilterToGlobalFilter(targetFilter: Filter) {
    DashboardUtil.getPageWidgets(this.dashboard).forEach((widget: PageWidget) => {
      if (widget.configuration.filters && widget.configuration.filters.length > 0) {
        _.remove(widget.configuration.filters, { field: targetFilter.field });
      }
    });

    _.remove(this.chartFilters, { field: targetFilter.field });

    delete targetFilter.ui.widgetId;
  }

  private _syncWidgetsAndFilters(customFields: CustomField[], fields: DatasourceField[], changeWidgetId: string) {
    customFields = CommonUtil.objectToArray(customFields);

    DashboardUtil.getPageWidgets(this.dashboard).forEach((widget: Widget) => {
      const widgetCongfiguration = { ...widget.configuration, customFields } as WidgetConfiguration;

      if (ChartType.MAP === (<PageWidgetConfiguration>widget.configuration).chart.type) {
        widgetCongfiguration['shelf'] = syncCustomFieldInWidgetShelf(widget, customFields);
        widgetCongfiguration['shelf'] = syncDatasourceAliasInWidgetPivot(widget, fields);
      } else {
        widgetCongfiguration['pivot'] = syncCustomFieldInWidgetPivot(widget, customFields);
        widgetCongfiguration['pivot'] = syncDatasourceAliasInWidgetPivot(widget, fields);
      }
      if (this.getWidgetComp(widget.id)) {
        this.broadCaster.broadcast('SET_WIDGET_CONFIG', {
          widgetId: widget.id,
          config: widgetCongfiguration,
        });
      }
    });
    this._organizeAllFilters(true).then(() => {
      this._syncFilterWidget(changeWidgetId);
    });
  }

  private _changeFieldAlias(changeField: DatasourceField, isReload: boolean = false) {
    DashboardUtil.getPageWidgets(this.dashboard).forEach((widget: Widget) => {
      if (ChartType.MAP === (<PageWidgetConfiguration>widget.configuration).chart.type) {
        widget.configuration['shelf'] = syncDatasourceAliasInWidgetPivot(widget, [changeField]);
      } else {
        widget.configuration['pivot'] = syncDatasourceAliasInWidgetPivot(widget, [changeField]);
      }

      if (this.getWidgetComp(widget.id)) {
        this.broadCaster.broadcast('SET_WIDGET_CONFIG', {
          widgetId: widget.id,
          config: widget.configuration,
          refresh: isReload,
        });
      }
    });
  }

  private _initViewPage() {
    this.showBoardLoading();

    this.useUnloadConfirm = false;

    {
      const boardInfo = this.dashboard;
      boardInfo.workBook = this.workbook;

      const mainDsList: Datasource[] = getMainDataSources(boardInfo);

      if (0 < mainDsList.length) {
        const linkedDsList: Datasource[] = []; //mainDsList.filter((item) => item.connType === ConnectionType.LINK);

        if (linkedDsList && 0 < linkedDsList.length) {
          this.showBoardLoading();

          const promises = [];

          linkedDsList.forEach((dsInfo) => {
            promises.push(
              new Promise<void>((res, rej) => {
                const boardDsInfo: BoardDataSource = DashboardUtil.findDataSourceOnBoard(boardInfo, dsInfo);
                this.datasourceService
                  .getDatasourceDetail(boardDsInfo['temporaryId'])
                  .then((ds: Datasource) => {
                    boardDsInfo.metaDataSource = ds;
                    if (boardInfo.configuration.filters) {
                      boardInfo.configuration.filters = ds.temporary.filters.concat(boardInfo.configuration.filters);
                    } else {
                      boardInfo.configuration.filters = ds.temporary.filters;
                    }

                    res();
                  })
                  .catch((err) => rej(err));
              }),
            );
          });

          Promise.all(promises)
            .then(() => {
              this._runDashboard(boardInfo, true);
              this.safelyDetectChanges();
            })
            .catch((error) => {
              this.commonExceptionHandler(error);
              this.hideBoardLoading();
            });
        } else {
          this.showBoardLoading();
          this._runDashboard(boardInfo, true);
        }
      } else {
        this.hideBoardLoading();
      }
    }
  }

  private _runDashboard(boardInfo: Dashboard, setInitialBoard: boolean = false) {
    this.initializeDashboard(boardInfo, LayoutMode.EDIT)
      .then((dashboard) => {
        this.hierarchy = new Hierarchy(DashboardUtil.getPageWidgets(dashboard), boardInfo);

        if ('NEW' === this.startupCmd.cmd) {
          this.isAppendLayout = true;
          switch (this.startupCmd.type) {
            case 'CHART':
              this.addChart();
              break;
            case 'TEXT':
              this.openTextWidgetEditor();
              break;
            case 'FILTER':
              this.openUpdateFilterPopup();
              break;
          }
        } else if ('MODIFY' === this.startupCmd.cmd) {
          this.editWidgetEventHandler(this.startupCmd.id);
        }

        this._organizeAllFilters().then(() => {
          setInitialBoard && (this.orgBoardInfo = _.cloneDeep(dashboard));
          this.hideBoardLoading();
          this.changeDetect.detectChanges();
        });
      })
      .catch((error) => {
        console.error(error);
        this.hideBoardLoading();
      });
  }

  private async callUpdateDashboardService(dashboardOptions?: UpdateDashboardOptions) {
    const param = {
      configuration: DashboardUtil.getBoardConfiguration(this.dashboard),
      ...dashboardOptions,
    };

    const settings = createConfigurationFromKtdGridLayout(this.gridCompomnent.layout, param.configuration);
    param.configuration.content = settings;
    param.configuration.layout.content = settings;

    const boardId = this.dashboard.id;
    try {
      await this.dashboardApiService.updateDashboard(boardId, param);
    } catch (error) {
      this.alertPrimeService.error(this.translateService.instant('msg.board.alert.update.board.error'));
    }

    const boardDs: BoardDataSource = DashboardUtil.getBoardDataSource(this.dashboard);
    const dsList: BoardDataSource[] = 'multi' === boardDs.type ? boardDs.dataSources : [boardDs];

    if (this.isChangeDataSource) {
      await this.dashboardApiService.connectDashboardAndDataSource(this.dashboard.id, dsList);
    }

    this.dashboardDomainService
      .loadDashboard(boardId)
      .pipe(take(1))
      .subscribe((dashboard) => {
        this.updateComplete.emit({ ...dashboard, workBook: this.workbook });
      });
  }

  private _syncFilterWidget(excludeWidgetId?: string) {
    const boardFilters: Filter[] = DashboardUtil.getBoardFilters(this.dashboard);

    this.getWidgetComps().forEach((widgetComp) => {
      if (widgetComp.isFilterWidget) {
        const filterWidget: FilterWidget = <FilterWidget>widgetComp.getWidget();
        const filter = boardFilters.find((item) =>
          DashboardUtil.isSameFilterAndWidget(this.dashboard, item, filterWidget),
        );

        if (filter) {
          filterWidget.configuration.filter = filter;
          this.dashboard = DashboardUtil.updateWidget(this.dashboard, filterWidget);
          this.broadCaster.broadcast('SET_WIDGET_CONFIG', {
            widgetId: filterWidget.id,
            config: filterWidget.configuration,
          });
        } else {
          this.removeWidget(widgetComp.getWidgetId());
        }
      }
    });

    const boardCastData = { filters: boardFilters };
    excludeWidgetId && (boardCastData['excludeWidgetId'] = excludeWidgetId);
    this.dashboardFiltersService.setDashboardFilters({ dashboardId: this.dashboard.id, ...boardCastData });
  }

  private _organizeAllFilters(isReloadWidget?: boolean): Promise<void> {
    return new Promise<void>((res1) => {
      initializeBoardFilters(this.dashboard);

      this.chartFilters = [];
      DashboardUtil.getPageWidgets(this.dashboard).forEach((widget) => {
        if (widget.type === 'page') {
          if ('filters' in widget.configuration && widget.configuration['filters'].length > 0) {
            widget.configuration['filters'].forEach((filter: Filter) => {
              filter.ui ||
                (filter.ui = {
                  importanceType: 'general',
                  widgetId: widget.id,
                });
              this.chartFilters.push(filter);
            });
          }
        }
      });

      const filters: Filter[] = DashboardUtil.getBoardFilters(this.dashboard);

      if (filters) {
        const promises = [];
        filters.forEach((filter) => {
          try {
            const filterWidget = DashboardUtil.getFilterWidgetByFilter(this.dashboard, filter);
            if (filterWidget) {
              promises.push(
                new Promise<void>((res2) => {
                  filterWidget.dashBoardId = this.dashboard.id;
                  filterWidget.configuration = createFilterWidgetConfiguration(filter);
                  if (isReloadWidget && null !== this.getWidgetComp(filterWidget.id)) {
                    this.reloadWidget(filterWidget);
                  }
                  res2();
                }),
              );
            } else {
              promises.push(
                new Promise<void>((res2) => {
                  const newFilterWidget: FilterWidget = createFilterWidget(filter, this.dashboard.id);
                  this.widgetService.createWidget(newFilterWidget, this.dashboard.id).then((result) => {
                    this.dashboard = DashboardUtil.addWidget(this.dashboard, _.merge(newFilterWidget, result));
                    res2();
                  });
                }),
              );
            }
          } catch (err) {
            console.error(err);
          }
        });

        DashboardUtil.getWidgets(this.dashboard).forEach((widget) => {
          if (
            'filter' === widget.type &&
            !DashboardUtil.getBoardFilters(this.dashboard).find((filter) =>
              DashboardUtil.isSameFilterAndWidget(this.dashboard, filter, widget),
            )
          ) {
            promises.push(
              new Promise<void>((res2) => {
                this.widgetService.deleteWidget(widget.id).then(() => {
                  this.removeWidget(widget.id);
                  res2();
                });
              }),
            );
          }
        });

        Promise.all(promises)
          .then(() => {
            res1();
          })
          .catch(() => this.hideBoardLoading());
      }
    });
  }
}
