<selfai-platform-dialog-header
  [header]="'workflow.cubes.enrichment-tool.modal-header' | translate"
  [nodeId]="nodeId"
  (clickSave)="onSubmit()"
  (closeDialog)="onCloseDialog()"
></selfai-platform-dialog-header>

@if(hasParameters) {
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool.field-label.sql-query' | translate }}">
    <ngx-monaco-editor
      [options]="editorOptions"
      [formControl]="form.controls.sqlQuery"
      class="p-inputtext"
    ></ngx-monaco-editor>
  </selfai-platform-form-field>

  <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool.field-label.data-source' | translate }}">
    <selfai-platform-data-source-selector
      [control]="form.controls.dataSourceId"
      class="h-15rem"
    ></selfai-platform-data-source-selector>
  </selfai-platform-form-field>
</form>
} @else {
<p-message text="{{ 'workflow.cubes.enrichment-tool.no-parameters' | translate }}" severity="error"></p-message>
}
