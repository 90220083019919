export const OLLAMA_API_PREFIX = '/ollama/'

export const AI_BOT_API_ROUTES = {
  CHATS: 'api/chats',
  CHAT: 'api/chat',
  CHAT_COMPLETIONS: 'v1/chat/completions',
  VERSION: 'api/version',
  URLS: 'api/urls',
  TAGS: 'api/tags',
}

export const AI_BOT_NAME = 'Selfai Bot'
