<div class="ai-chat position-absolute">
  <p-sidebar baseZIndex="-1" [(visible)]="visible" (visibleChange)="setChatVisibility($event)" position="right" styleClass="w-6">
    <ng-template pTemplate="header">
      <div class="flex w-full justify-content-between align-items-center">
        <div class="flex flex-column">
          <div class="mb-2">{{'msg.ai-chat.title' | translate}}</div>

          <div class="flex align-items-center">
            <p-toggleButton
              styleClass="ai-chat-toggle"
              [(ngModel)]="showAllChats"
              onIcon="pi pi-bars"
              offIcon="pi pi-bars"
            />

            <label class="ml-2 mr-2">{{'msg.ai-chat.model' | translate}}: </label>

            <p-dropdown
              [options]="models"
              [(ngModel)]="selectedModel"
              optionLabel="name"
              [placeholder]="'msg.ai-chat.model-placeholder' | translate"
            />
          </div>
        </div>

        <div class="text-lg">{{selectedChatTitle}}</div>

        <div></div>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="flex" style="height: calc(100vh - 180px)">
        <div *ngIf="showAllChats" class="flex w-3">
          <div class="flex-1 p-0 flex-grow-1 fadein animation-duration-300">
            <app-chat-sidebar
              [chat$]="chats$"
              [selectedChatId]="selectedChatId"
              (selectChat)="selectChat($event)"
              (addNewChat)="addNewChat($event)"
              (removeChat)="removeChat($event)"
              (renameChat)="renameChat($event)"
            ></app-chat-sidebar>
          </div>
        </div>

        <div class="flex" [ngClass]="{'w-full': !showAllChats, 'w-9': showAllChats}">
          <div class="flex-1 p-0 flex-grow-1 w-full">
            <app-chat-box [messages]="messages"></app-chat-box>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex p-3 align-items-center mt-auto border-top-1 surface-border gap-3">
        <input
          id="message"
          type="text"
          pInputText
          autocomplete="off"
          [disabled]="!(chats$ | async)?.length"
          [placeholder]="'msg.ai-chat.send-message-placeholder' | translate"
          class="flex-1 w-full sm:w-auto border-round"
          [(ngModel)]="textContent"
          (keydown.enter)="sendMessage(textContent)"
        />
        <div class="flex w-full sm:w-auto gap-3">
          <button
            pButton
            pRipple
            [disabled]="!(chats$ | async)?.length"
            [label]="'msg.ai-chat.send-message' | translate"
            icon="pi pi-send"
            class="w-full sm:w-auto"
            (click)="sendMessage(textContent)"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-sidebar>
</div>
